import { createApp } from 'vue';
import './scss/main.scss'
import axios from "axios";
import Multiselect from 'vue-multiselect';

const api = axios.create({
    baseURL: "/api/v2/",
    withCredentials: true,
    xsrfCookieName: "csrftoken",
    xsrfHeaderName: "X-CSRFToken"
});

createApp({
    components: {
        Multiselect
    },
    delimiters: ["[[", "]]"],
    data() {
        return {
            subjects: [],
            isLoading: false,
            actualString:"",
            selectedSubject: null,
            version: null,
            contactName: null,
            contactPhone: null,
            contactEmail: null,
            errors: null,
            orderFormBusy: false,
            nooptionsslotname: "noOptions",
            noresultslotname: "noResult"
        }
    },
    computed: {
        multiselectSettings: function() {
            return {
                label: "name",
                trackBy: "id",
                placeholder: "Název nebo IČO úřadu",
                options: this.subjects,
                searchable: true,
                internalSearch: false,
                clearOnSelect: false,
                showLabels: false,
                preserveSearch: false,
                openDirection: "bottom",
                optionHeight: 60
            }
        },
        selectedSubjectName: function() {
            return this.selectedSubject ? this.selectedSubject.name : null
        },
        selectedSubjectIco: function() {
            return this.selectedSubject ? this.selectedSubject.ico : null
        },
        teaserLink: function() {
            return this.selectedSubject ? `${VUE_APP_HOST}/teaser/${this.selectedSubject.teaser_hash}` : null
        }
    },
    mounted: function() {
        var urlParams = new URLSearchParams(window.location.search)
        var name = urlParams.get("name")
        var ico = urlParams.get("ico")
        var version = urlParams.get("version")

        if (name && ico) {
            this.selectedSubject = {name: name, ico: ico}
            this.focusForm()
        }
        if (version) {
            this.version = version
        }
    },
    methods: {
        searchSubjects: function(query) {
            this.isLoading = true;
            this.subjects = [];
            this.actualString = query;

            let params = {
                q: query,
                version: this.version,
                limit: 1000,
                offset: 0
            }

            api.get("buyer/", { params: params })
                .then(r => {
                    if(query==this.actualString){
                        this.subjects = r.data.data
                    }
                })
                .catch(e => console.log(e))
                .finally(() => {
                    if(query==this.actualString){
                        this.isLoading = false;
                    }
                })
        },
        focusForm: function() {
            this.$refs.order_form.scrollIntoView(true)
            this.$refs.order_form_focus.focus()
        },
        focusSearchForm: function() {
            this.$el.scrollIntoView(true)
            this.$refs.search_form.querySelector('input').focus()
        },
        gotToTeaser: function() {
            window.location.href = this.teaserLink
        },
        submitOrder: function() {
            this.orderFormBusy = true

            api.post(
                "order/",
                {
                    name: this.selectedSubjectName,
                    ico: this.selectedSubjectIco,
                    version: this.version,
                    contact_name: this.contactName,
                    contact_phone: this.contactPhone,
                    contact_email: this.contactEmail
                }
            )
            .then(r => {
                this.errors = null
                window.location.href = "/order-complete/"
            })
            .catch(r => {
                this.errors = r.response.data.errors
            })
            .finally(() => this.orderFormBusy = false)
        }
    }
}).mount('#main_app');

